import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  height: 3.125rem;
  padding: 0.5rem 0.9375rem;
  border: 2px solid #ccc;
  background: #272727;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
  caret-color: white;
  font-size: 16px;
  &:hover {
    border-color: #fffefc;
  }
  &:focus {
    outline: none;
    border-color: #fffefc;
    background-color: #222;
    color: #fffefc;
  }
`

export default function InputBox(props) {
  const { title } = props

  return (
    <div>
      <div
        style={{
          textTransform: 'uppercase',
          marginBottom: '15px',
          fontFamily: "'Roboto Mono',sans-serif",
          fontSize: '14px',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </div>
      <Input type="text" />
    </div>
  )
}
