import styled from "styled-components"

export const JumboH1 = styled.h1`
  margin-bottom: 20px;
  font-size: 52px;
  font-weight: 300;
  text-transform: capitalize;
  font-family: "Museo 300", Georgia, sans-serif;
  line-height: 110%;
  font-weight: 600;
  text-shadow: 3px 0px 7px rgba(255, 255, 255, 0.8), -3px 0px 7px rgba(255, 255, 255, 0.8), 0px 4px 7px rgba(255, 255, 255, 0.8);
`

export const Subtitle = styled.div`
  font-family: "Roboto Mono", sans-serif;
  font-size: 0.8125rem;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 400;
  opacity: 0.8;
  text-align: center;
  /* white-space: nowrap; */
`
