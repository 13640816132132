import styled from 'styled-components'

export const Button = styled.div`
  font-family: 'Museo Sans 100', Arial, sans-serif;
  width: min-content;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  cursor: pointer;
  padding: 1.2rem 1.4rem;
  display: flex;
  align-items: center;
  color: #fffefc;
  background-color: #222222;

  &:hover div:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

export const ButtonText = styled.div`
  margin-top: 5px;
  white-space: nowrap;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 115%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }
`
