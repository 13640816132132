export default async function magnify(imgID, zoom) {
  var img, glass, w, h, bw
  img = document.getElementById(imgID)

  /* Create magnifier glass: */
  glass = document.createElement("DIV")
  glass.setAttribute("class", "img-magnifier-glass")

  /* Insert magnifier glass: */
  img.parentElement.insertBefore(glass, img)

  const image_url = "/build/Radar_Chart_MAG.jpg"

  // create blob url
  const blob = await fetch(image_url).then((r) => r.blob())
  const blob_url = URL.createObjectURL(blob)
  glass.style.backgroundImage = "url(" + blob_url + ")"
  glass.style.backgroundRepeat = "no-repeat"
  glass.style.backgroundSize = img.width * zoom + "px " + img.height * zoom + "px"
  bw = 3
  w = glass.offsetWidth / 2
  h = glass.offsetHeight / 2
  glass.style.display = "none"

  /* Execute a function when someone moves the magnifier glass over the image: */
  glass.addEventListener("mousemove", moveMagnifier)
  img.addEventListener("mousemove", moveMagnifier)

  /*and also for touch screens:*/
  glass.addEventListener("touchmove", moveMagnifier)
  img.addEventListener("touchmove", moveMagnifier)

  // document.addEventListener('scroll', moveMagnifier)

  function moveMagnifier(e) {
    // var pos, x, y
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault()
    /* Get the cursor's x and y positions: */
    // pos = getCursorPos(e)
    // x = pos.x
    // y = pos.y

    const { x, y } = getCursorPos(e)
    /* Prevent the magnifier glass from being positioned outside the image: */
    if (x > img.width - w / zoom) {
      // x = img.width - w / zoom
      setTimeout(() => (glass.style.display = "none"), 0)
      // glass.style.display = 'none'
      return
    }
    if (x < w / zoom) {
      // x = w / zoom
      setTimeout(() => (glass.style.display = "none"), 0)
      // glass.style.display = 'none'
      return
    }
    if (y > img.height - h / zoom) {
      // y = img.height - h / zoom
      setTimeout(() => (glass.style.display = "none"), 0)
      // glass.style.display = 'none'
      return
    }
    if (y < h / zoom) {
      // y = h / zoom
      setTimeout(() => (glass.style.display = "none"), 0)
      // glass.style.display = 'none'
      return
    }
    setTimeout(() => (glass.style.display = "block"), 0)
    // glass.style.display = 'block'
    /* Set the position of the magnifier glass: */
    glass.style.left = x - w + "px"
    glass.style.top = y - h + "px"
    /* Display what the magnifier glass "sees": */
    glass.style.backgroundPosition = "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px"
  }

  function getCursorPos(e) {
    var a,
      x = 0,
      y = 0
    e = e || window.event
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect()
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left
    y = e.pageY - a.top
    /* Consider any page scrolling: */
    x = x - window.pageXOffset
    y = y - window.pageYOffset
    return { x: x, y: y }
  }
}
