import React, { useState, useEffect, useReducer, createContext } from "react"
import styled from "styled-components"
import $ from "jquery"
import { MapContainer, TileLayer } from "react-leaflet"
import L from "leaflet"
import "leaflet-easybutton"
import "leaflet-easybutton/src/easy-button.css"
import "leaflet/dist/leaflet.css"
import { Logo, Navbar, NavLink, NavItemsContainer, NavDropdown, NavDropdownBox, NavDropdownItem } from "./styled-components/Navbar.style"
import { Container, Section } from "./styled-components/Section.style"
import { JumboH1, Subtitle } from "./styled-components/Text.style"
import { Button, ButtonText } from "./styled-components/Button.style"

import magnify from "./utils/magnifier"

import Dropdown from "./Dropdown"
import InputBox from "./InputBox"

import DEVELOPEMENT_PLAIN_ICON from "./assets/Icons/development plan icon.svg"
import GAZETTTE_NOTIFICATIONS_ICON from "./assets/Icons/gazette notifications icon.svg"
import LAND_POOLING_ICON from "./assets/Icons/land pooling icon.svg"
import MPD_2041_ICON from "./assets/Icons/mpd 2041 icon.svg"
import NCR_2021_ICON from "./assets/Icons/ncr 2021 icon.svg"
import GREEN_DEVELOPEMENT_AREA_ICON from "./assets/Icons/green development area icon.svg"

const RightImage = styled.img`
  opacity: 0.5;

  /* c-hero-img */
  position: absolute;
  z-index: -1;
  left: 30%;
  top: -12%;
`

const MainContent = styled.div`
  color: #222;
  text-align: center;
`

const MainP = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  font-family: "Roboto Mono", sans-serif;
  line-height: 130%;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0;
  text-shadow: 3px 0px 7px rgba(255, 255, 255, 0.8), -3px 0px 7px rgba(255, 255, 255, 0.8), 0px 4px 7px rgba(255, 255, 255, 0.8);
`

const IconsContainer = styled.div`
  z-index: 10;
  opacity: 1;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  min-width: 70px;
`

const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

const StyledTab = styled.div`
  font-size: 14px;
  color: ${(props) => (props.active ? "rgba(50, 49, 47, 1)" : "rgba(50, 49, 47, 0.5)")};
  border-bottom: ${(props) => (props.active ? "2px solid black" : "none")};
  padding-bottom: 5px;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #32312f;
  }
`

const SmallBox = styled.div`
  display: flex;
  padding: ${(props) => props.padding || "1.875rem"};
  height: 15.625rem;
  width: 15.2775rem;
  justify-content: center;
  /* align-items: center; */
  border: 2px solid #ccc;
  cursor: pointer;

  -webkit-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: #222;
  }
`

const MainContentForm = styled.div`
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
`

const FooterGrid = styled.div`
  display: -ms-grid;
  display: grid;
  margin-top: 60px;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #fffefc;
  text-align: left;
`

const FooterLink = styled.a`
  display: block;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  color: #ccc;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;

  &:hover {
    opacity: 1;
    color: #fffefc;
  }
`

const FooterSocialLink = styled.div`
  margin-right: 1rem;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.6;
  }
`

const SubmitButton = styled.button`
  padding: 1rem 3.125rem;
  color: #222;
  background-color: #fffefc;
  text-align: center;
  border: none;
  transition: background-color 0.2s ease-in-out;
  font-family: "Roboto Mono", sans-serif;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`

const ACTIONS = { UPDATE_TABS: "UPDATE_TABS" }

const setActiveTab = (activeIndex, state) => {
  let tabs = [...state.tabs]

  tabs = tabs.map((tab, index) => {
    if (activeIndex === index) return { ...tab, active: true }
    return { ...tab, active: false }
  })

  return { ...state, tabs }
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_TABS:
      return setActiveTab(action.payload, state)
    default:
      return state
  }
}

const initialState = {
  tabs: [{ title: "Girl Squad / Miami, FL", active: true }, { title: "Outdoor Adventures / Portland, OR" }, { title: "Trendy Eats / Cincinnati, OH" }, { title: "Dog Lovers / San Francisco, CA" }],
}

export const GlobalContext = createContext()

const firstBlocks = [
  {
    title: "MPD 2041",
    img: MPD_2041_ICON,
  },
  {
    title: "National Capital Regional Plan 2021",
    img: NCR_2021_ICON,
  },
  {
    title: "Land Pooling",
    img: LAND_POOLING_ICON,
  },
  {
    title: "Green development area",
    img: GREEN_DEVELOPEMENT_AREA_ICON,
  },
  {
    title: "Development Policies",
    img: DEVELOPEMENT_PLAIN_ICON,
  },
  {
    title: "Gazette Notifications & Circulars",
    img: GAZETTTE_NOTIFICATIONS_ICON,
  },
]

const secondBlocks = [
  {
    title: "Our Blog",
    subtitle: "Read",
    description: "Stay up-to-date on all things Geosocial. Read helpful articles, tips, data updates, and announcements on our blog.",
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec53383fd201fee78147857_blog.svg",
  },
  {
    title: "Our Blog",
    subtitle: "Read",
    description: "Stay up-to-date on all things Geosocial. Read helpful articles, tips, data updates, and announcements on our blog.",
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec53383fd201fee78147857_blog.svg",
  },
  {
    title: "Our Blog",
    subtitle: "Read",
    description: "Stay up-to-date on all things Geosocial. Read helpful articles, tips, data updates, and announcements on our blog.",
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec53383fd201fee78147857_blog.svg",
  },
]

const companyImages = [
  {
    img: `${process.env.PUBLIC_URL}/leaflet-icon.png`,
    width: 180,
  },
  { img: `${process.env.PUBLIC_URL}/react-icon.png`, width: 150 },
  { img: `${process.env.PUBLIC_URL}/python-icon.png`, width: 175 },
  { img: `${process.env.PUBLIC_URL}/arcgis-icon.png`, width: 125 },
  { img: `${process.env.PUBLIC_URL}/postgres-icon.png`, width: 150 },
]

const firstDropdownItems = [
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c9f746960aa0709c50_site%20selection.svg",
    text: "Site Selection",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c90bde33901d0d40c1_geomarketing.svg",
    text: "Geomarketing",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c90bde33034c0d40c0_leasing%20strategy.svg",
    text: "Leasing Strategy",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c97545710fa1038bdc_merchandising.svg",
    text: "Merchandising",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424caf801f46d4b4b220e_tenant%20rep.svg",
    text: "Tenant Representation",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424ca0673ae71b1a41b75_urban%20planning.svg",
    text: "Urban Planning",
  },
]

const secondDropdownItems = [
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c9f746960aa0709c50_site%20selection.svg",
    text: "Site Selection",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c90bde33901d0d40c1_geomarketing.svg",
    text: "Geomarketing",
  },
  {
    img: "https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec424c90bde33034c0d40c0_leasing%20strategy.svg",
    text: "Leasing Strategy",
  },
]

export default function LandingPage() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [map, setMap] = useState(null)
  const [boundCenter, setBoundCenter] = useState([28.62648, 77.09913])

  useEffect(() => {
    setTimeout(() => magnify("magnify-image", 4), 500)

    $("#first-dropdown").on("mouseover", () => {
      $("#first-dropdown-box").css({ display: "grid" })
    })

    $("#first-dropdown").on("mouseout", () => {
      $("#first-dropdown-box").css({ display: "none" })
    })

    $("#second-dropdown").on("mouseover", () => {
      $("#second-dropdown-box").css({ display: "grid" })
    })

    $("#second-dropdown").on("mouseout", () => {
      $("#second-dropdown-box").css({ display: "none" })
    })

    // on window resize

    $("#top-section-bg-img").css({
      backgroundPosition: window.innerWidth === 1920 ? "right -140px bottom 30%" : `right ${-140 + (1920 - window.innerWidth) / 10}px bottom 30%`,
      height: window.innerWidth === 1920 ? "75vh" : `calc(75vh - ${(1920 - window.innerWidth) / 10}px)`,
    })

    $(window).on("resize", () => {
      // if window size is 1920 make background position right -140px bottom 30% other wise move to left proportionally
      $("#top-section-bg-img").css({
        backgroundPosition: window.innerWidth === 1920 ? "right -140px bottom 30%" : `right ${-140 + (1920 - window.innerWidth) / 10}px bottom 30%`,
        height: window.innerWidth === 1920 ? "75vh" : `calc(75vh - ${(1920 - window.innerWidth) / 10}px)`,
      })
    })
  }, [])

  // const resetMap = () => {
  //   map.setZoom(8)
  //   // setBoundCenter([28.62648, 77.09913])
  //   map.setView([28.62648, 77.09913])
  // }

  // useEffect(() => {
  //   if (map) {
  //     // Initialize map
  //     $("#map").css({ height: "750px", width: "100%" })

  //     // reset map
  //     resetMap()

  //     // hide the bottom right leaflet attribution icon
  //     $(".leaflet-control-attribution").hide()

  //     // Add home button on map using easybutton plugin
  //     const homeBtn = L.easyButton("fa-home", resetMap, "Home")
  //     homeBtn.addTo(map)
  //   }
  // }, [map])

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <div className="App">
        <Navbar>
          <Logo href="#">
            <img
              src={`${process.env.PUBLIC_URL}/terrainmen_logo.png`}
              style={{
                minWidth: 25,
                maxWidth: 45,
              }}
            />
            <span style={{ opacity: 0.95, letterSpacing: 2 }}>TERRAINMEN</span>
          </Logo>
          <NavItemsContainer>
            {/* <NavLink>Data</NavLink>
              <div id="first-dropdown">
                <NavDropdown>Use Cases</NavDropdown>
                <NavDropdownBox id="first-dropdown-box" gridTemplateColumns="1fr 1fr">
                  {firstDropdownItems.map((item) => (
                    <NavDropdownItem>
                      <div>
                        <img src={item.img} alt="" width="32px" />
                      </div>
                      <div>{item.text}</div>
                    </NavDropdownItem>
                  ))}
                </NavDropdownBox>
              </div>
              <div id="second-dropdown">
                <NavDropdown>Resources</NavDropdown>
                <NavDropdownBox id="second-dropdown-box">
                  {secondDropdownItems.map((item) => (
                    <NavDropdownItem>
                      <div>
                        <img src={item.img} alt="" width="32px" />
                      </div>
                      <div>{item.text}</div>
                    </NavDropdownItem>
                  ))}
                </NavDropdownBox>
              </div>
              <NavLink>Contacts</NavLink> */}
            <NavLink>
              <a style={{ all: "unset" }} href="login.php">
                Login
              </a>
            </NavLink>
          </NavItemsContainer>
        </Navbar>
        <Section
          id="top-section-bg-img"
          style={{
            background: `url('${process.env.PUBLIC_URL}/landin-page-main-big.webp') no-repeat`,
            backgroundSize: "108%",
          }}
        >
          <Container style={{ margin: "auto", height: "100%" }}>
            <MainContent style={{ height: "100%" }}>
              <JumboH1>We are connected to the land</JumboH1>
              <MainP>Analytics | Networking | Advisory</MainP>
            </MainContent>
          </Container>
        </Section>

        <Section style={{ display: "flex", flexDirection: "column", justifyContent: "between", alignItems: "center" }}>
          <IconsContainer>
            {companyImages.map((cimg, index) => (
              <FlexCenter key={index}>
                <img
                  src={cimg.img}
                  alt=""
                  width={cimg.width}
                  style={{
                    filter: "grayscale(100%)",
                  }}
                />
              </FlexCenter>
            ))}
          </IconsContainer>
        </Section>

        <HorizontalLine />

        <Section style={{ zIndex: 2, background: "#faf7ef", margin: "4rem 0px" }}>
          <Container display="flex" gap="2rem" padding="0px">
            <div className="left" style={{ position: "relative" }}>
              <img id="magnify-image" src={`${process.env.PUBLIC_URL}/Radar_Chart_MAG.jpg`} alt="" srcSet="" width="600px" />
            </div>

            <div
              className="right"
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
                // alignItems: 'center',
                flexDirection: "column",
              }}
            >
              <h1 style={{ display: "flex", gap: "1rem", fontSize: "36px", fontWeight: "500", alignItems: "center" }}>
                <div style={{ background: "rgba(34, 34, 34, 0.2)", borderRadius: "18px", padding: "8px" }}>
                  <div style={{ background: "#222", borderRadius: "12px", padding: "5px" }}>
                    <img src={`${process.env.PUBLIC_URL}/white-marker.png`} alt="Geospatial Icon" style={{ maxWidth: 28 }} />
                  </div>
                </div>
                <span>Geospatial Datasets</span>
              </h1>
              <span style={{ fontSize: "13px", opacity: ".8" }}>COVERAGE - DELHI, HARYANA & UTTAR PRADESH</span>
              <p style={{ opacity: ".8", marginBottom: "3rem" }}>
                Traditional maps alone miss critical part of the development journey. Terrainmen India pieces together various important aspects such as LULC, transport network, ownership pattern,
                development code etc.
              </p>
              {/* <Button>
                <ButtonText>EXPLORE THE DATASET</ButtonText>
              </Button> */}
            </div>
          </Container>
        </Section>

        <HorizontalLine />

        {/* <Section style={{ marginTop: "6rem" }}>
          <Container>
            <h1
              style={{
                display: "flex",
                gap: "1rem",
                fontSize: "36px",
                fontFamily: '"Museo 300",Georgia,sans-serif',
                fontWeight: "500",
                alignItems: "center",
              }}
            >
              Data Preview
            </h1>
            <p style={{ opacity: ".8", marginBottom: "3rem", maxWidth: "50%" }}>
              Geosocial data is organized into 72 segments and mapped at the block group level. Explore the map below for more details.
            </p>

            <div style={{ display: "flex", gap: "1.5rem", marginBottom: "2rem" }}>
              {state.tabs.map((tab, i) => (
                <StyledTab active={tab.active} onClick={() => dispatch({ type: ACTIONS.UPDATE_TABS, payload: i })}>
                  {tab.title}
                </StyledTab>
              ))}
            </div>

            <div style={{ display: "flex", height: "750px" }}>
              <div style={{ width: "100%", background: "#fffefc", padding: "2rem" }}>
                <div style={{ display: "flex", gap: ".8rem" }}>
                  <img src="https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5f218ac265db51d1f5ea2cab_girl_squad_square.jpg" alt="" width="50" height="50" style={{ borderRadius: "50%" }} />
                  <div>
                    <span style={{ fontSize: "13px", fontWeight: "400", opacity: ".8" }}>EC07</span>
                    <div style={{ marginTop: "-5px", fontSize: "21px" }}>Girl Squad</div>
                  </div>
                </div>

                <div style={{ margin: "1rem 0 .5rem 0", fontSize: ".9rem" }}>Social Topics</div>
                <HorizontalLine />
              </div>

              <div style={{ width: '60%' }}>
                <MapContainer id="map" center={boundCenter} zoom={8} minZoom={3} maxZoom={20} whenCreated={setMap}>
                  <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} />
                </MapContainer>
              </div>
            </div>
          </Container>
        </Section> */}

        <Section style={{ marginTop: "4rem" }}>
          <Container display="flex" style={{ alignItems: "center" }}>
            {/* left container */}
            <div style={{ width: "40%" }}>
              <h1 style={{ fontSize: "36px", fontWeight: "500", fontFamily: '"Museo 300",Georgia,sans-serif' }}>Land Policy Advisory</h1>
              <p style={{ opacity: ".8", marginBottom: "3rem" }}>
                For all your policy related issues we are your one stop shop. Browse through our collection of use cases to better understand our approach.
              </p>
              {/* <Button>
                <ButtonText>FIND YOUR USE CASE</ButtonText>
              </Button> */}
            </div>

            {/* right container */}
            <div
              style={{
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              {firstBlocks.map((block) => (
                <SmallBox>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1.4rem",
                      flexDirection: "column",
                      marginTop: "2rem",
                    }}
                  >
                    <img width="80" src={block.img} alt="" />
                    <Subtitle>{block.title}</Subtitle>
                  </div>
                </SmallBox>
              ))}
            </div>
          </Container>
        </Section>

        <Section style={{ marginTop: "6rem", marginBottom: "6rem" }}>
          <Container padding="5rem" style={{ background: "#fffefc" }}>
            <div style={{ display: "flex", gap: "2.5rem", alignItems: "center" }}>
              <div
                style={{
                  width: "50%",
                  fontSize: "2.75rem",
                  fontFamily: '"Museo 300",Georgia,sans-serif',
                  fontWeight: "300",
                  lineHeight: "110%",
                }}
              >
                Want to see the data for yourself?
              </div>

              <div style={{ marginLeft: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    cursor: "pointer",
                  }}
                >
                  <Button>
                    <ButtonText>DOWNLOAD SAMPLE DATA</ButtonText>
                  </Button>

                  <div style={{ padding: "1.2rem", background: "#222222" }}>
                    <img src={`${process.env.PUBLIC_URL}/download_icon.png`} style={{ maxWidth: 35 }} />
                  </div>
                </div>
              </div>
            </div>
          </Container>

          {/* <Container
            display="flex"
            style={{
              flexDirection: "column",
              maxWidth: "34.375rem",
              margin: "6rem auto 0 auto",
            }}
          >
            <div
              style={{
                fontSize: "2.25rem",
                fontWeight: "500",
                fontFamily: '"Museo 300",Georgia,sans-serif',
                textAlign: "center",
              }}
            >
              Resources
            </div>
            <div
              style={{
                fontWeight: "300",
                fontFamily: "Museo Sans 300",
                fontSize: ".9rem",
                lineHeight: "170%",
                color: "#333",
                textAlign: "center",
                margin: "1rem 0 1rem 0",
              }}
            >
              Our introductory materials, webinars, and blog give you the education, insights, and inspiration you need to get value from the data right away.
            </div>
          </Container>

          <Container>
            <div style={{ display: "flex", gap: "1rem" }}>
              {secondBlocks.map((block) => {
                return (
                  <SmallBox style={{ gap: "1rem", flexDirection: "column", width: "100%", height: "100%" }}>
                    <div style={{ display: "flex", gap: ".8rem", alignItems: "center" }}>
                      <img src={block.img} alt="" width="60" />
                      <div>
                        <Subtitle style={{ textAlign: "initial" }}>{block.subtitle}</Subtitle>
                        <div
                          style={{
                            fontSize: "24px",
                            fontWeight: "500",
                            fontFamily: "Museo Sans 500",
                          }}
                        >
                          {block.title}
                        </div>
                      </div>
                    </div>

                    <div style={{ fontSize: "14px", fontFamily: "Museo Sans 300", lineHeight: "170%" }}>{block.description}</div>
                  </SmallBox>
                )
              })}
            </div>
          </Container> */}
        </Section>

        {/* <Section style={{ background: "#272727", color: "#fffefc", marginTop: "4rem", paddingBottom: "5rem" }}>
          <Container>
            <div
              style={{
                fontSize: "13px",
                fontFamily: '"Roboto Mono", sans-serif',
                textTransform: "uppercase",
                lineHeight: "130%",
                opacity: ".8",
              }}
            >
              CONTACT US
            </div>
            <div
              style={{
                margin: "0.625rem 0 2.5rem 0",
                fontSize: "2.25rem",
                fontWeight: "500",
                fontFamily: '"Museo 300",Georgia,sans-serif',
              }}
            >
              Get started with Geosocial today
            </div>

            <MainContentForm>
              <InputBox title="FIRST NAME" />
              <InputBox title="LAST NAME" />
              <InputBox title="WORK EMAIL*" />
              <InputBox title="ORGANIZATION" />
              <Dropdown title="INDUSTRY*" options={["Select One...", "Retail", "Restaurant", "Real State", "CPG", "E-Commerce", "Marketing", "Other"]} />

              <Dropdown
                title="USE CASE*"
                options={["Select One...", "Site Selection", "Leasing Strategy", "Tenant Representation", "Geomarketing", "Merchandising", "Urban Planning", "Customer Profiling", "Other"]}
              />
              <Dropdown title="ARE YOU USING A MAPPING PROVIDER?*" options={["Select One...", "Yes", "No"]} />
              <div></div>
              <SubmitButton>Submit</SubmitButton>
            </MainContentForm>
          </Container>
        </Section> */}

        {/* footer */}
        <Section style={{ background: "#222", color: "#fffefc" }}>
          <Container>
            <footer>
              <FooterGrid>
                <div>{/* <img width="60%" src="https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec68e9e2a878575c22160af_logotype_white.svg" alt="" /> */}</div>
                <div></div>
                {/* <div>
                  <div>Data</div>
                  <FooterLink href="#">Geosocial Dataset</FooterLink>
                </div> */}
                <div>
                  <div>Contact</div>
                  <FooterLink href="#">+91 99535 95755</FooterLink>
                  {/* <FooterLink href="#">Leasing Strategy</FooterLink>
                  <FooterLink href="#">Tenant Rep</FooterLink>
                  <FooterLink href="#">Geomarketing</FooterLink>
                  <FooterLink href="#">Merchandising</FooterLink>
                  <FooterLink href="#">Urban Planning</FooterLink> */}
                </div>
                <div>
                  <div>Address</div>
                  <FooterLink href="#">B-15, Lawrence Road Industrial Area, New Delhi 110035</FooterLink>
                  {/* <FooterLink href="#">Blog</FooterLink>
                  <div style={{ marginTop: "1.2rem" }}>About</div>
                  <FooterLink href="#">Contact</FooterLink>
                  <FooterLink href="#">Privacy</FooterLink> */}
                </div>
              </FooterGrid>
              <HorizontalLine style={{ background: "#494949" }} />
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.25rem" }}>
                <div
                  style={{
                    fontSize: "14px",
                    opacity: "0,6",
                    color: "#ccc",
                    fontWeight: "300",
                  }}
                >
                  Copyright © 2023 Terrainmen India
                </div>
                <FooterSocialLink>
                  {/* <a href="#">
                    <img src="https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ee29b47597aec2f42ea33a1_linkedin.svg" alt="" />
                  </a> */}
                </FooterSocialLink>
              </div>
            </footer>
          </Container>
        </Section>
      </div>
    </GlobalContext.Provider>
  )
}
