import React from 'react'
import styled from 'styled-components'

const StyledDropdown = styled.select`
  font-family: 'Museo Sans 300', Arial, sans-serif;
  color: #fffefc;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;

  height: 3.125rem;
  padding: 0.5rem 0.9375rem;
  border: 2px solid #ccc;
  background-color: #272727;
  width: 100%;
  transition: border-color 0.2s ease-in-out;

  appearance: none;
  background-image: url('https://uploads-ssl.webflow.com/5ea9c246ac68005779e98501/5ec4270b0bde3355030d4aab_arrow%20down.svg');
  background-repeat: no-repeat;
  background-position: right;

  &:hover {
    border-color: #fffefc;
  }

  &:focus {
    outline: none;
    border-color: #fffefc;
  }
`

export default function Dropdown(props) {
  const { title, options } = props

  return (
    <div>
      <div
        style={{
          textTransform: 'uppercase',
          marginBottom: '15px',
          fontFamily: "'Roboto Mono',sans-serif",
          fontSize: '14px',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </div>
      <StyledDropdown>
        {options.map((op) => {
          return <option value={op}>{op}</option>
        })}
      </StyledDropdown>
    </div>
  )
}
