import styled from "styled-components"

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  max-width: 1200px;
  margin: auto;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const Logo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  color: rgba(34, 34, 34, 0.8);
  font-weight: 700;
  font-size: 1.8rem;
  font-family: "Museo 300", Georgia, sans-serif;
`

export const NavItemsContainer = styled.div`
  display: flex;
  gap: 40px;
`

export const NavLink = styled.div`
  font-size: 1.2rem;
  color: #222;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;

  display: inline-block;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #222;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

export const NavDropdown = styled.div`
  font-size: 14px;
  color: #222;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
`

export const NavDropdownBox = styled.div`
  display: grid;
  display: none;
  grid-template-columns: ${(props) => props.gridTemplateColumns || "1fr"};
  grid-template-rows: auto;
  row-gap: 0.6rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: white;
  position: absolute;
  border-radius: 0.2rem;
  z-index: 1000;
`

export const NavDropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.6rem;
  gap: 0.4rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`
