import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import LandingPage from "./LandingPage"
import Maps from "./Maps"

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        {/* <Route exact path="/map" component={Maps} /> */}
      </Switch>
    </Router>
  )
}
