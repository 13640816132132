import styled from 'styled-components'

export const Section = styled.section`
  padding: 10px 50px;
  @media (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const Container = styled.div`
  display: ${(props) => (props.display ? props.display : 'block')};
  gap: ${(props) => (props.gap ? props.gap : '1rem')};
  padding: ${(props) => (props.padding ? props.padding : '20px')};
  background-color: transparent;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`
